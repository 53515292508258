import { useParams } from '@solidjs/router'
import SubscriptionGate from '~/components/subscription-gate/subscription-gate'
import TranslationView from '~/components/translation-view/translation-view'
import { TranslationParams } from '~/types/routes/series/translation'

export default function Translation() {
  const { id } = useParams<TranslationParams>()
  return (
    <SubscriptionGate requiredRole='translation'>
      <TranslationView chapterId={id} />
    </SubscriptionGate>
  )
}
